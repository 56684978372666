import Navigo from 'navigo';

import homeRoute from './Routes/home';
import pricingRoute from './Routes/pricing';

import ShowCtaOnScroll from './Components/Animation/ShowCtaOnScroll';
import Tooltips from './Components/Tooltip/Tooltips';

const router = new Navigo('/');

router.on('/', () => {
  homeRoute();
});

router.on('/preise-ubersicht', () => {
  pricingRoute();
});

router.notFound(() => true);

router.resolve();

ShowCtaOnScroll();
Tooltips();
